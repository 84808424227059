$pt-root-ui-font-path: "./pt-root-ui" !default;

@font-face {
  font-family: "PT Root UI";
  src: url("#{$pt-root-ui-font-path}/pt-root-ui_light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PT Root UI";
  src: url("#{$pt-root-ui-font-path}/pt-root-ui_regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Root UI";
  src: url("#{$pt-root-ui-font-path}/pt-root-ui_medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PT Root UI";
  src: url("#{$pt-root-ui-font-path}/pt-root-ui_bold.ttf") format("truetype");
  font-weight: 700;
}
