@import "../assets/fonts/geometria";
@import "../assets/fonts/pt-root-ui";

:root {
  /** primary **/
  --ion-color-primary: #5aacd8;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-blue: #2c3541;
  --ion-color-dark-blue-active: #313a47;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #868e98;
  --ion-color-medium-warm: #999;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #eeedee;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-warm: #f2f2f2;

  /** brand **/
  --ion-color-brand: #252b33;
  --ion-color-brand-rgb: 37, 43, 51;
  --ion-color-brand-contrast: #fff;
  --ion-color-brand-contrast-rgb: 255, 255, 255;
  --ion-color-brand-shade: #1e2023;
  --ion-color-brand-tint: #383a3e;
  --ion-color-secondary-selected: #32db64;
  --ion-color-blue: #79b6f8;
  --ion-color-white: #fff;
  --ion-color-grey: #999;
  --ion-color-light-grey: #ddd;
  --ion-color-dark-grey: #252b33;
  --ion-color-darker-grey: #4a4e54;
  --ion-color-darker-blue: #191c1f;
  --ion-color-menu-blue: #2c3541;
  --ion-color-black: #000;
  --ion-color-grey-bg: #eee;

  // Fonts
  --ion-font-family: geometria, roboto, open sans, sans-serif;
  --ion-text-color: var(--ion-color-black);
}
